@media (min-width:375px) and (max-width:767px){
    .logo_icon {
        background-size: contain;
        padding: 17px 20px 30px;
        max-width: 300px;
    }
    .logo_img {
        width: 100%;
        max-width: 130px;
        padding-top: 0;
    }
    .LoginLeft {
        width: 100%;
        padding: 20px 50px;
    }
    .login_details {
        margin: 0 auto;
        width: 90%;
        padding: 10px;
    }
    .mainCopyright {
        display: none;
    }
    .mobileCopyright {
        display: block;
    }
    .otp_box {
        margin: 0 auto;
        width: 100%;
    }
    .enterOtp {
        margin-right: 0;
    }
    .login_ {
        font-size: 24px;
        line-height: 36px;
        color: #31405C;
    }
    .welcome_note {
        font-size: 12px;
        line-height: 18px;
    }
    .input_label {
        font-size: 13px;
        line-height: 18px;
    }
    .input_box {
        font-size: 12px;
    }
    .inputIcon {
        top: 40px
    }
    /*---- Start Navbar ----*/
    .logo{
        width: 100%;
        max-width: 78px;
    }
    .userName {
        font-size: 10px;
        line-height: 18px;
        padding: 0 10px;
    }
    .userDetails {
        font-size: 12px;
        line-height: 18px;
        padding-right: 1rem;
    }
    .welcomeIcon {
        width: 10px;
    }
    .userMenu {
        transform: translate(-12px, 22px) !important;
    }
    .navMain {
        display: none;
    }
    .mobileMain{
        display: block;
    }
    /*---- End Navbar ----*/

    /*---- Start Customer Page ----*/
    .headingCustomer {
        display: none;
    }
    .dashBoardMain .CustomersSearch {
        display: none;
    }
    .CustomersSearchMobile .cusomer_box {
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .filter_box {
        margin: 0 8px;
    }
    .filter_box:after {
        display: none;
    }
    .BillPeriod {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #908DA1;
        display: block;
    }
    .activities_user .billingDate{
        padding: 0;
    }
    .billBox{
        width: 50%;
        margin: 10px 0;
    }
    .userIcon {
        margin-right: 10px;
    }
    .CustomerMobileBox{
        margin-bottom: 15px;
    }
    /*---- End Customer Page ----*/

    /*---- Start View and Edit Profile Page ----*/

    .editprofile{
        display: none;
    }
    .editprofileMobile{
        display: block;
    }
    .ChnageProfileBox{
        display: flex;
        justify-content: space-between;
    }
    .profile_modal {
        width: 120px;
        height: 124px;
        max-width: 120px;
        max-height: 124px;
        border-radius: 50%;
    }
    .edit_iconmodal {
        bottom: 0px;
        right: 0px;
    }
    .button_box,.CustomerBox{
        /* display: none; */
    }
    .Mobilebutton_box{
        display: block;
    }
    /*---- End View and Edit Profile Page ----*/

    /*---- Start Billing Page ----*/

    .pageHeading {
        font-size: 18px;
        line-height: 27px;
    }
    .filterHeading {
        font-size: 10px;
        line-height: 15px;
        margin-left: 0;
    }
    .filter_box {
        font-size: 12px;
        line-height: 15px;
        padding: 9px 5px;
    }
    .BillingInvoice {
        display: block;
    }
    .mobileBillingBox {
        width: 50%;
        margin-bottom: 15px;
    }
    .sync_btn {
        padding: 4px 10px;
        font-size: 12px;
        line-height: 24px;
    }
}
@media (min-width:768px){
    .mainCopyright, .navMain,.CustomerBox {
        display: block;
    }
    .CustomersSearchMobile,.editprofileMobile,.Mobilebutton_box,.CustomerMobileBox {
        display: none;
    }
    .mobileMain{
        display: none !important;
    }
}

@media (max-width:768px){
    .location-svg {
        margin: 3rem 0 1rem 0;
        max-width: 100px !important;
    }
    .sub-title {
        margin: 2rem auto 1rem;
        padding-top: 1rem;
    }
    .mainBox .custom-button {
        margin-bottom: 1rem;
    }
    .mapboxgl-popup-content {
        height: 100%;
        max-height: 330px;
        overflow: auto;
    }
}
@media screen and (max-width: 1370px), 
       screen and (max-height: 710px) {
        .logostopped {
            max-width: 125px !important;
        }
        .location-svg {
            max-width: 98px !important;
            margin: 0rem 0 1rem 0;
        }
       .mainBox .sub-title {
            padding-top: 0rem;
            max-width: 300px;
            margin: 0rem auto 1rem;
        }
        .mainBox .custom-button {
            padding: 5px 3rem;
            margin: 1rem 0 0 0;
        }
        .CustomerBox {
            max-height: 392px;
        }
}
@media screen and (min-width: 1371px) and (max-width: 1685px)
        {
        .CustomerBox {
            max-height: 560px;
        }
}

@media (max-width:480px){
    .locationBottomn_ .sub-title {
        margin: 20px auto 0;
        font-weight: 400;
        max-width: 300px;
        font-size: 16px;
    }
    .downloadBtn_ {
        padding: 14px 50px!important;
        font-size: 20px!important;
    }
    .midlocatiobBox_ {
        margin: 7rem 0 0;
    }
    .locationBottomn_ {
         margin: 7rem 0 0;
    }
}
@media (max-width:395px){
.midlocatiobBox_ {
    margin: 4rem 0 0;
}
.locationBottomn_ {
     margin: 4rem 0 0;
}
.location-svg {
    max-width: 114px !important;
}
/* .logostopped {
    max-width: 105px !important;
} */
}

@media (max-width:375px){
    .midlocatiobBox_ {
        margin: 5rem 0 0;
    }
    .locationBottomn_ {
         margin: 5rem 0 0;
    }
}