/*---- Start Common Css ----*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    background: #fff4f8;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.navbarContain {
    background-image: linear-gradient(to right, #1C3276, #4C69C3, #1C3276);
    padding: 10px 0;
}

#main {
    margin: 40px 0;
    min-height: calc(100vh - 66px - 311px);
    display: flex;
    flex-direction: column;
    place-content: center;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
}

a {
    text-decoration: none;
}

a:-webkit-any-link:focus-visible {
    outline: 0;
}

p {
    margin: 0;
}

textarea {
    resize: none;
}

textarea:focus-visible {
    outline: 0;
}
/*---- Scrollbar-Css ----*/
.scrollbar
{
    background: #B7BDC6;
    width: 6px;
    height: 10%;
}
.scrollbar-track
{
    background:#F1F2F4
}
.scrollbar-thumb {
    background: #B7BDC6;
    border-radius:16px;
    border:0px solid #F1F2F4;
}
::-webkit-scrollbar
{
    background: #B7BDC6;
    width: 6px;
    height: 10%;
}
::-webkit-scrollbar-track {
    background-color:#F1F2F4
}
::-webkit-scrollbar-thumb {
    background: #B7BDC6;
    border-radius:16px;
    border: 0px solid #F1F2F4;
}
::-moz-scrollbar
{
    background: #B7BDC6;
    width: 6px;
    height: 10%;
}
::-moz-scrollbar-track {
    background: #B7BDC6;
}
::-moz-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border: 0px solid #fff;
}
/*---- End Scrollbar Css ----*/
/*---- Start Navbar ----*/
.navMain {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0px (#000000 / 0.5);
}

.nav_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
}

.user_details {
    display: flex;
    align-items: center;
}

.userName {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #31405C;
    padding: 0 1rem;
}

.userIcon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    filter: drop-shadow(0px 10px 15px rgba(238, 34, 113, 0.1));
}

.userMenu {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 21%);
    border-radius: 10px;
    border: none;
    transform: translate(-52px, 22px) !important;
    padding: 0 !important;
}

.user_menudetails {
    margin: 5px;
}

.dropdownIcon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
}

.user-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #62708A;
    cursor: pointer;
    text-decoration: none;
}

.user-item:hover {
    color: #62708A;

}

.userDetails {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #62708A;
    padding: 0 2rem;
}

.userDetails::after {
    margin-left: 1rem !important;
}

/*--- Start Dashboard ----*/
.CustomersSearch {
    display: flex;
    align-items: center;
}

.cusomer_box {
    display: flex;
    align-items: center;
}

.customerHeading {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.005em;
    color: #31405C;
    margin: 0;
    padding-right: 15px;
}

.searchBox_ {
    position: relative;
}

.searchBox_ .form-control {
    background: #FFFFFF;
    border-radius: 10px;
    border: none;
}

.searchIcon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.filterHeading {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #A29FB4;
    margin: 0;
    margin-left: auto;
}

.filter_box {
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(238, 34, 113, 0.05);
    border-radius: 10px;
    border: none;
    padding: 9px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #62708A;
    margin: 0 15px;
}

.filter_box:after {
    margin-left: 50px;
}

.weeklydot {
    display: inline-block;
    background: #ED7F02;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-right: 5px;
}

.twoWeekdot {
    display: inline-block;
    background: #6A6BFF;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-right: 5px;
}

.fourWeeklydot {
    display: inline-block;
    background: #E53A3D;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-right: 5px;
}

.Manual {
    display: inline-block;
    background: #1B83FE;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-right: 5px;
}

.sync_btn {
    background: linear-gradient(105deg, #F7775A 9.18%, #EE1E72 51.28%, #CB1C91 90.82%);
    box-shadow: 0px 10px 30px rgba(238, 34, 113, 0.15);
    border-radius: 10px;
    border: none;
    padding: 7px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F8FAFC;
}

.syncIcon {
    margin-right: 5px;
}

/*---- Start Customer table ----*/
.table_Customer {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.activities_user {
    background: #ffc8fb38;
    border-radius: 10px;
    overflow: hidden;
    /* z-index: -2; */
    position: relative;
}

.headingCustomer {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #62708A;
}

.subheadingCustomer {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #908DA1;
    padding: 10px 0;
}

.subheadingCustomer:first-child {
    border-radius: 10px 0 0 10px;
}

.subheadingCustomer:last-child {
    border-radius: 0 10px 10px 0;
}

.subheadingCustomer .userIcon {
    margin: 0 10px;
}

.sentBox {
    color: #FFFFFF;
    background: #5EBE3C;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 25px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.PendingBox {
    color: #FFFFFF;
    background: #FCA21C;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 25px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.reviewBtn {
    border: none;
    background: #31405C;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #F8FAFC;
    padding: 5px 20px;
}

.filterMain:first-child {
    margin-left: 15px;
}

.filter_item {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #62708A;
    margin: 0 13px;
}

.filter_item:hover {
    color: #62708A;
}

/*---- End Dashboard ----*/

/*---- Start View and edit Profile ----*/
.backbutton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.back_btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #EF2570;
}

.back_btn:hover {
    color: #EF2570;
}

.backBtnIcon {
    margin-right: 10px;
}

.pageHeading {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.005em;
    color: #31405C;
    margin-top: 10px;
}

.editprofile {
    background: #FFF;
    border-radius: 30px;
    margin: 15px 0;
    height: calc(100vh - 210px);
    overflow: auto;
}

.profileBox {
    padding: 30px;
}

.profile_modal {
    width: 150px;
    height: 164px;
    max-width: 150px;
    max-height: 164px;
    border-radius: 50%;
}

.custom-file-upload {
    position: relative;
}

.edit_iconmodal {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    position: absolute;
    bottom: 6px;
    right: 10px;
}

.changePassword {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #EF2570;
    padding-left: 10px;
}

.changePassword:hover {
    color: #EF2570;
}

.Discard_btn {
    background: #31405C;
    box-shadow: 0px 10px 30px rgba(238, 34, 113, 0.15);
    border-radius: 10px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F8FAFC;
    padding: 7px 20px;
    margin: 0 10px;
}

.save_btn {
    background: linear-gradient(105deg, #F7775A 9.18%, #EE1E72 51.28%, #CB1C91 90.82%);
    box-shadow: 0px 10px 30px rgba(238, 34, 113, 0.15);
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F8FAFC;
    padding: 7px 20px;
    margin: 0 10px;
    border: none;

}

/*---- Start Password change modal ----*/
#ChangePassword .modal-content {
    border: none !important;
    border-radius: 10px !important;
}

.modal-header {
    border-bottom: 2px solid #FFE9F2;
}

.headingSmall_ {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.005em;
    color: #31405C;
    margin: 0;
}

.modal_btn {
    margin: 30px auto 20px;
}

.btn-close:focus {
    outline: 0 !important;
    box-shadow: none !important;
    opacity: 1;
}

/*---- End Password change modal ----*/

/*---- End View and edit Profile ----*/

/*---- Start Automatic billing  ----*/
.BoxOuter {
    background: #FFFFFF;
    border: 2px solid rgba(239, 37, 112, 0.2);
    border-radius: 10px;
    padding: 20px;
}

.BillingInvoice {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.invoice_heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #31405C;
    margin: 0;
}

.invoiceNumber {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #62708A;
    margin-right: 25px;
}

.billingDetails {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #62708A;
    margin: 0;
}

.customerName {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #31405C;
    margin: 0;

}

.customerAddress {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #A29FB4;
    padding-right: 42px;
}

.automatic_Billing {
    width: 100%;
    border-spacing: 0 10px;
}

.billingBox {
    background: #FEF4F8;
    border-radius: 5px;
}

.automatic_Billing .activities_user {
    border-bottom: 1px solid #FBF1F5;
}

.billingDate {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #62708A;
    padding-left: 10px;
}

.billingHeading {
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #31405C;
}

.billingSubheading {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #A29FB4;
}

.automatic_Billing .subheadingCustomer {
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #31405C;
    padding: 14px 10px;
}

.totalBill {
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: right;
    color: #31405C;
}

.BillingBox .Discard_btn {
    box-shadow: none;
    margin: 0 0 0 51px !important;
}

.billingBox .headingCustomer {
    padding: 10px;
}

.automatic_Billing .subheadingCustomer:first-child {
    display: flex;
    align-items: flex-start;
}

.bikkdetailsIcon {
    margin-right: 10px;
}

.SubTotal {
    justify-content: space-between;
    display: flex;
}

.SubTotalText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #31405C;
    margin: 15px 15px 0px 15px;
}

.PreviousBillHeading {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #62708A;
}

/*---- Start Edit Billing Details ----*/

.BillingModalHeader_ {
    width: 100%;
}

.BillingModalHeading {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#BillingDetails,
#EditBillingDetails {
    max-width: 1000px;
}
#BillingDetails  .BillingInvoice ,
#EditBillingDetails  .BillingInvoice {
    justify-content: space-between;
}

#BillingDetails .BillingInvoice {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SubTotal:last-child {
    margin-bottom: 10px;
}

.TotalBill {
    border-top: 1px solid #FBF1F5;
}

#BillingDetails .Discard_btn {
    box-shadow: none;
}

/*---- End Edit Billing Details ----*/

/*---- End Automatic billing  ----*/

/*---- Start Setup Billing ----*/
.ActivityMain {
    position: relative;
}

.BillIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 45px;
}

.SetupBill .input_box {
    padding: 10px 15px;
}

.EditBillingDetails .BillingBox {
    padding-bottom: 15px;
    border-bottom: 1px solid #FBF1F5;
}

.ActivityBox {
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(238, 34, 113, 0.07);
    border-radius: 10px;
    padding: 20px;
}

.ActivityBox .BillIcon {
    top: 13px;
}

.ActivityBox .input_box {
    padding: 10px;
}

.CancelActivity {
    position: absolute;
    right: 0;
    top: 14px;
}

.AddMoreBtn {
    background: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #EF2570;
    border: none;
    margin: 15px 0;
}

.AddMoreIcon {
    margin-right: 10px;
}

/*---- Start Setup Billing ----*/
 
/*---- Start Manual Billing ----*/

.manualBilling .BoxOuter {
    margin-bottom: 20px;
}
.createBill {
    background: url(../img/createbill.svg);
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    border-radius: 15px;
}
.newBill {
    background: rgba(239, 37, 112, 0.03);
    border: 2px dashed rgba(239, 37, 112, 0.3);
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #EF2570;
    padding: 5px 30px;
    margin-top: 18px;
}
.addIcon {
    margin-right: 10px;
}
.setupBillingHeading {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.005em;
    color: #31405C;
}
.manualBillinBox .input_box {
    padding: 10px 15px;
}
#CreateBill {
    max-width: 1000px;
}
#CreateBill  .BillingInvoice {
    justify-content: space-between;
}
#CreateBill .modal-body {
    height: 100%;
    max-height: 600px;
    overflow: auto;
}
.activityHeading {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #62708A;
}
.BillingPeriod {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 20px 0;
    border-bottom: 1px solid #FBF1F5    ;
}
.CreateBillIcon {
    position: absolute;
    right: 10px;
    top: 14px;
    width: 20px;
    height: 20px;
}
.BillingPeriod .input_box {
    padding: 10px 15px;
}
.billPeriod {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #A29FB4;
    padding: 10px 20px;
}

/* Start Sidebar Css */
.main {
    display: flex;
    background: #FFFFFF;
    width: 100%;
    overflow: auto;
}
.sidebar {
    max-width: 240px;
    position: fixed;
    height: 100%;
    width: 100%;
    display: inline-block;
    background: #000;
    box-shadow: 10px 20px 40px #0000000d;
    border-radius: 0px 30px 30px 0px;
}
.nav_link:hover {
    background: transparent linear-gradient(90deg, #FFFFFF40 0%, #F0512333 100%);
}
.nav_link:hover a {
    color: #fff !important;
}
.sidebarLogo {
    padding: 20px;
}
.sidebarLogo img{
    background: white;
    padding: 10px;
    border-radius: 0px 19px;
}
.nav_link.active .sidebar_icon {
    display: inline-block;
    margin-right: 4px;
    transition: all .5s;
}
.nav_link .sidebar_icon {
    display: none;
}
.nav_link.active {
    background: transparent linear-gradient(90deg, #FFFFFF40 0%, #F0512333 100%);
    border-left: 4px solid #fff;
}
.nav_link {
    font-weight: 400;
    font-size: 18px;
    color: #FFFF !important;
    text-decoration: none;
}
.navIcon {
    font-size: 32px;
}
.nav_main{
    padding: 0;
}
.nav_items {
    padding: 10px 23px;
    display: flex;
    align-items: center;
    border-left: 5px solid transparent;
}
.nav_items.active {
    border-left: 5px solid #ffffff5b;
    background-color: #ffffff5b;
}
.sidebarFooter {
    position: absolute;
    bottom: 25px;
}

.commonrightBoxNav {
    min-width: calc(100% - 240px);
    margin-left: 240px;
    height: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
}
.logBox{
    height: 100%;
}
/*End Sidebar Css*/


/* Dashboard CSS */
.dash-cards .card {
    background-color: #f8f9fa;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.commonrightBox {
    min-width: calc(100% - 240px);
    margin-left: 240px;
    height: auto;
    /* margin-bottom: 5%; */
}
.dash-cards .card-body {
    padding: 1.5rem;
}

.dash-cards .card-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.dash-cards .card-text {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dash-cards .user-count {
    margin-left: 0.5rem;
}

.dash-cards .fa-users,
.dash-cards .fa-user-check,
.dash-cards .fa-user-plus {
    font-size: 1.5rem;
    color: #b037b6;
}

.dash-cards a{
    text-decoration: none !important;
    cursor: pointer !important;
    color: unset !important;
}
/* End Dashboard CSS */

.table_Customer {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.activities_user {
    background: #ffc8fb38;
    border-radius: 10px;
    overflow: hidden;
    z-index: 0;
}
.userTableHeading {
    background-color: #bd6eb9;
    border-radius: 10px;
    height: 100%;
    position: sticky;
    top: 0px;
    z-index: 1;
}
.headingCustomer {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    padding: 15px 10px;
}

.subheadingCustomer {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #908DA1;
    padding: 15px 10px;
}

.subheadingCustomer:first-child, .headingCustomer:first-child {
    border-radius: 10px 0 0 10px;
}

.subheadingCustomer:last-child, .headingCustomer:last-child {
    border-radius: 0 10px 10px 0;
}

.subheadingCustomer .userIcon {
    margin: 0 10px;
}
.deleteButton{
    background-color: #3c0c3a; 
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer !important;
}
.deactivate {
    background-color: #996EBD;
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer !important;
    width: 100%;
    max-width: 110px;
}
.logoutButton {
    background-color: #7d1c81d1;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    cursor: pointer !important;
}
.paginationBox {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
}
.paginationBox li {
    padding:5px 10px;
}
.selected {
    background: #c771c2;
    border: none;
    border-radius: 3px;
    color: #FFF;
}
.paginationIcon{
    color: #3c0c3a;
}
.CustomerBox {
    height: 100%;
    max-height: 700px;
    overflow: auto;
}
.pagination {
    margin: 15px 0;
}
.headingPage {
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 20px 0; */
    position: sticky;
    top: 80px;
    z-index: 2;
}
.searchFilterBox{
    display: flex;
    align-items: center;

}
.logostopped {
    width: 100%;
    max-width: 150px !important;
}
.location-svg {
    width: 100%;
    max-width: 140px !important;
    margin: 2rem 0 1rem 0;
}
.mainBox .title {
    font-size: 22px;
    margin: 10px auto;
}
.sub-title {
    font-size: 18px;
    padding-top: 2rem;
    width: 100%;
    max-width: 300px;
    margin: 3rem auto 1rem;
}
.mainBox .custom-button {
    background: #000;
    font-size: 22px;
    padding: 5px 3rem;
    border-radius: 11px;
    margin: 2rem 0 0 0;
}
.searchBox:focus-visible{
    outline: none !important;
}
.searchBox {
    align-items: center;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 6px 14px #0000001a;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    position: relative;
    border-radius: 0;
    border: none;
    padding: 8px 30px;
    border-radius: 10px;
    position: relative;
    z-index: 2;
}
.searchFilterBox{
    position: relative;
}
.searchIcon {
    position: absolute;
    left: 16px;
    z-index: 3;
    font-size: 22px;
    color: #CCC;
    margin: 0 5px;
    width: 20px;
    height: 20px;
}

.filterBox{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 6px 14px #0000001a;
    margin-right: 1rem;
}

.react-confirm-alert-overlay {
    background: rgb(0 0 0 / 62%) !important;
}
.react-confirm-alert-body {
    text-align: center;
}
.react-confirm-alert-button-group {
    justify-content: center;
}

/* Forgot Emailtemplate */
.boxOuter {
    width: 50%;
    margin: 5rem auto;
    background: #fff;
    box-shadow: 2px 2px 20px #ccc;
    padding: 30px;
    border-radius: 20px;
}
.input_label {
    color: #31405C;
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;
    padding-bottom: 10px;
}

.input_box {
    border: 1px solid rgba(255, 238, 245, 1);
    background: #FFFBFD;
    border-radius: 15px;
    color: rgba(162, 159, 180, 1);
    font-size: 15px;
    font-weight: 400;
    padding: 10px 40px;
}

.form-group {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 42px;
    left: 10px;
}
/*---Eye Icon*---*/
.show-icon {
    background-image: url('../img/Hide.svg');   
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 13px;
    right: 19px;
    cursor: pointer;
}
.hide-icon.hide-icon {
    background-image: url('../img/eyeIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 13px;
    right: 19px;
    cursor: pointer;
}
/*---Eye Icon End*---*/

.loactionbox_ {
 
    max-width: 381px;
    width: 100%;
    margin: 0 auto;
}
.locationBottomn_ {
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 8rem 0 0;
}
.downloadBtn_ {
    color: #ffff!important;
    text-decoration: none;
    font-weight: 500;
    padding: 21px 85px!important;
    display: inline-block;
    border-radius: 50px!important;
} 
.midlocatiobBox_{
    margin: 8rem 0 0;
}
.locationBottomn_ .sub-title {
    margin: 0;
    font-weight: 400;
    max-width: initial;
    font-size: 22px;
}