body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  height: 100vh;
}

.location-stopped {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: xx-large;
  text-align: center;
  flex-direction: column;
}

.location-permission {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.location-permission .title {
  color: var(---1d1e27-primary-light-theme);
  text-align: center;
  font: normal normal 600 50px/61px Montserrat;
  letter-spacing: 0px;
  color: #1D1E27;
  opacity: 1;
}

.location-permission .sub-title {
  color: var(---1d1e27-primary-light-theme);
  text-align: center;
  font: normal normal normal 25px/33px Open Sans;
  letter-spacing: 0.4px;
  color: #1D1E27;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .location-permission .title {
    color: var(---1d1e27-primary-light-theme);
    text-align: center;
    font: normal normal 600 25px/30px Montserrat;
    letter-spacing: 0px;
    color: #1D1E27;
    opacity: 1;
  }

  .location-permission .sub-title {
    color: var(---1d1e27-primary-light-theme);
    text-align: center;
    font: normal normal normal 15px/22px Open Sans;
    letter-spacing: 0.4px;
    color: #1D1E27;
    opacity: 1;
  }
}

.location-permission .custom-button {
  color: var(---f4f8fd-screen-bg);
  text-align: center;
  font: normal normal bold 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #F4F8FD;
  text-transform: uppercase;
  opacity: 1;
  padding: 16px 83px;
}

.location-stopped .title {
  color: var(---1d1e27-primary-light-theme);
  text-align: center;
  font: normal normal 600 50px/61px Montserrat;
  letter-spacing: 0px;
  color: #1D1E27;
  opacity: 1;
}

.location-stopped .sub-title {
  color: var(---1d1e27-primary-light-theme);
  text-align: center;
  font: normal normal normal 25px/33px Open Sans;
  letter-spacing: 0.4px;
  color: #1D1E27;
  opacity: 1;
}

.location-stopped .custom-button {
  color: var(---f4f8fd-screen-bg);
  text-align: center;
  font: normal normal bold 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #F4F8FD;
  text-transform: uppercase;
  opacity: 1;
  padding: 16px 83px;
}

.mapboxgl-marker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker {
  position: absolute;
  bottom: 0;
}

.flag-marker {
  position: absolute;
  bottom: -5px;
  left: -5px;
}

.mapboxgl-popup {
  top: 50% !important;
  right: 0 !important;
  left: unset !important;
  transform: translate(-10%, -50%) !important;
  max-width: 459px !important;
  z-index: 5;
  width: -webkit-fill-available;
}

.mapboxgl-popup-content {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 50px #0000001A !important;
  border-radius: 20px !important;
  opacity: 1 !important;
}

.mapboxgl-popup-close-button {
  right: 15px !important;
  top: 10px !important;
  font-size: x-large;
}

.detailPopUp {
  padding: 22px 27px;
}

.helpIconBox {
  width: 30px;
  height: 30px;
  background: #1D1E27 0% 0% no-repeat padding-box;
  opacity: 1;
  display: inline-flex;
  border-radius: 50%;
}

.helpIcon {
  width: 15px;
  height: 15px;
  margin: 7px 7.8px 7.8px 7px;
}

.helpTitle {
  color: #1D1E27;
  text-align: left;
  font: normal normal 600 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #1D1E27;
  opacity: 1;
  margin-left: 12px;
}

.headerTitle {
  text-align: left;
  font: normal normal bold 18px/28px Open Sans;
  letter-spacing: 0.29px;
  color: #1D1E27;
  opacity: 1;
  margin-bottom: 15px;
}

.planType {
  font: normal normal bold 18px/28px Open Sans;
  letter-spacing: 0.29px;
  color: #B037B6;
  opacity: 1;
  /* margin-left: 12px; */
}

.scrolldown {
  text-align: center;
  font: normal normal medium 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #7E84A3;
  opacity: 1;
  z-index: 15;
  width: 100vw;
  position: absolute;
  bottom: 0px;
  padding-bottom: 1px;
  background-color: white;
}

.fieldContainer {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  /* font: normal normal normal 18px/22px Open Sans; */
  letter-spacing: 0.29px;
  color: #1D1E27;
  opacity: 1;
}

.location-width {
  width: 100px;
}

.fieldValue {
  color: var(---1d1e27-primary-light-theme);
  text-align: right;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #1D1E27;
  opacity: 1;
}

.mapboxgl-popup-content .table_title {
  width: 30%;
  font-weight: bold;
  text-align: left !important;
}

.mapboxgl-popup-tip {
  display: none;
}

.accordion-item {
  all: unset !important;
}

.accordion-button {
  padding: 0 !important;
}

.accordion-body {
  padding: 0 !important;
}

.accordion-button:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.accordion-button:not(.collapsed) {
  color: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

@media (min-width:767.98px) {
  .scrolldown {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .mapboxgl-map {
    overflow: unset !important;
  }

  .mapboxgl-popup {
    top: 48.4% !important;
    right: 0 !important;
    left: unset !important;
    transform: unset !important;
    max-width: 100vw !important;
    z-index: 10;
  }
}

.mapboxgl-popup-content {
  overflow: auto;
  max-height: 100vh;
}

@media (max-width: 767.98px) {
  .detailPopUp {
    padding: 5px 27px;
  }

  .logo {
    margin: 0;
  }

  .mapboxgl-popup {
    top: 48.4% !important;
    right: 0 !important;
    left: unset !important;
    transform: unset !important;
    max-width: 100vw !important;
    z-index: 10;
  }

  .location-width {
    width: unset;
  }

  .fieldContainer {
    display: block;
    margin-bottom: 15px;
  }

  .title {
    display: block;
    margin-bottom: 5px;
  }

  .fieldValue {
    text-align: unset;
    display: block;
  }

  .mapboxgl-popup-content {
    overflow: unset;
    max-height: unset;
  }
}

@media (max-width: 767.98px) {
  .location-stopped .title {
    color: var(---1d1e27-primary-light-theme);
    text-align: center;
    font: normal normal 600 25px/30px Montserrat;
    letter-spacing: 0px;
    color: #1D1E27;
    opacity: 1;
  }

  .location-stopped .sub-title {
    color: var(---1d1e27-primary-light-theme);
    text-align: center;
    font: normal normal normal 16px/33px Open Sans;
    letter-spacing: 0.25px;
    color: #1D1E27;
    opacity: 1;
    width: 214px;
  }

  .location-stopped .custom-button {
    color: var(---f4f8fd-screen-bg);
    text-align: center;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #F4F8FD;
    text-transform: uppercase;
    opacity: 1;
  }

  .location-stopped .location-svg {
    width: 242px;
    height: 237px;
    opacity: 1;
  }
}

.pulse {
  width: 21px;
  height: 21px;
  border-radius: 50px;
  color: #FFF;
  text-align: center;
  font-family: sans-serif;
  text-transform: uppercase;
  animation: animate-pulse 3s linear infinite;
  cursor: pointer;
  transform: translate(0, 6px);
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(176, 55, 182, 0.7), 0 0 0 0 rgba(176, 55, 182, 0.7);
  }

  40% {
    box-shadow: 0 0 0 50px rgba(176, 55, 182, 0.0), 0 0 0 0 rgba(176, 55, 182, 0.7);
  }

  80% {
    box-shadow: 0 0 0 50px rgba(176, 55, 182, 0.0), 0 0 0 30px rgba(176, 55, 182, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(176, 55, 182, 0.0), 0 0 0 30px rgba(176, 55, 182, 0);
  }

}
/* Start AdditionalMap Css */
.main_info {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: center;
}
.main_info .main_location {
  background: #fff;
  text-align: center;
  padding: 8px 20px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #1D1E27;
  border-radius: 3px;
}
.innerShortTxt
{
  text-overflow: ellipsis;
  overflow: hidden;.commonrightBox {
    min-width: calc(100% - 240px);
    margin-left: 240px;
    height: 100vh;
    width: 100%;
}
  position: relative;
  display: block; 
}
.main_info .copyIcon {
  width: 18px;
  height: 18px;
  filter: contrast(0.5);
  transform: rotateY(180deg);
}
.main_info .main_comment {
  background: #fff;
  text-align: center;
  padding: 8px 20px;
  display: inline-block;
  font-size: 12px;
  color: #1D1E27;
  border-radius: 3px;
}
.disclamer{
  margin-top: 15px;
}
/* End AdditionalMap Css */


.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
  bottom: calc(100vh - 650px) !important;
  /* bottom: 50px; */
}
/* .mapboxgl-control-container {
  position: relative;
  height: 100%;
} */