/* Start Login Css */
figure {
    margin: 0;
}
.login {
    background: linear-gradient(to right, #300732 0%, #7a1b7e 51.56%, #b037b6 100%);
    border-radius: 30px;
    margin: 15px;
    height: calc(100vh - 30px);
    overflow: auto;
}

.logo_icon {
    background: url(../img/logobg.png);background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px 30px 54px;
    max-width: 499px;
    display: flex;
}

.logo_img {
    width: 100%;
    max-width: 170px;
    display: inline-block;
    padding-top: 20px;
}

.LoginLeft {
    padding-left: 30px;
}

.copyright {
    color: #FFE1E2;
    font-size: 14px;
    line-height: 21px;
    padding: 30px;
}

.login_details {
    background: #FFFFFF;
    margin: 50px auto;
    border-radius: 20px;
    padding:30px;
}

.login_ {
    font-size: 32px;
    line-height: 48px;
    font-weight: 500;
    color: #31405c;
    text-align: center;
}

.welcome_note {
    color: #908da1;
    font-size: 15px;
    line-height: 23px;
    width: 100%;
    max-width: 330px;
    text-align: center;
    margin: 0 auto;
}

.input_label {
    color: #31405C;
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;
    padding-bottom: 10px;
}

.input_box {
    border: 1px solid rgba(255, 238, 245, 1);
    background: #FFFBFD;
    border-radius: 15px;
    color: rgba(162, 159, 180, 1);
    font-size: 15px;
    font-weight: 400;
    padding: 10px 40px;
}

.form-group {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 41px;
    left: 10px;
}
/*---Eye Icon*---*/
.show-icon {
    background-image: url('../img/Hide.svg');   
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 10px;
    right: 19px;
    cursor: pointer;
}
.hide-icon.hide-icon {
    background-image: url('../img/eyeIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 13px;
    right: 19px;
    cursor: pointer;
}
/*---Eye Icon End*---*/
.submit_btn {
    background: linear-gradient(to right, #300732 0%, #7a1b7e 51.56%, #b037b6 100%);
    display: inline-block;
    border: none;
    font-size: 18px;
    color: #fff;
    border-radius: 15px;
    padding: 15px;
    font-weight: 600;
    position: relative;

}

.submit_btn::before {
    content: '';
    position: absolute;
    background: url(../img/nextIcon.svg);
    width: 45px;
    height: 45px;
    right: 5px;
    top: 6px;
    background-size: cover;
    background-repeat: no-repeat;
}

.submit_text {
    position: relative;
}

.submit_text::after {
    content: '';
    position: absolute;
    background: url(../img/submitbtnbg.svg);
    width: 59px;
    height: 41px;
    left: -50px;
    top: -8px;
    background-size: cover;
    background-repeat: no-repeat;
}

.forget_password a{
    color: #ad36b3;
    text-align: center;
    padding: 15px;
}
.backIcon{
    margin-right: 10px;
}
.form-control:focus {
    color: #62708a !important;
    background-color: #FFFBFD !important;
    border-color: #FFEEF5 !important;
    outline: 0;
    box-shadow: none !important;
}
.form-control{
    padding: 0.375rem 2.53rem !important
}
.form-check-input:focus {
    border-color: #FFEEF5 !important;
    outline: 0;
    box-shadow: none !important;
}
/*===Start Custom checkbox===*/
.remember_me{
    width: 20px !important;
    height: 20px !important;
    border-radius: 4px;
    margin: auto;
    vertical-align: middle;
    appearance: none;
    border: 1px solid #ffeef5 !important;
    background-color: #FFEEF5;
    box-shadow: 0px 10px 15px #a2d5ff2b;
    cursor: pointer;
    margin-right: 10px;
}

.form-check-input:checked[type=checkbox] {
    /* background-image: url("../img/7-Check.svg") !important; */
    background-repeat: no-repeat !important;
    /* background-size: 23px 14px !important; */
    background-position: calc(100% - 0px) !important;
    background-color: #a732ac;
    }

.form-check-input:focus {
    box-shadow: none;
}

/*===Start Custom checkbox===*/
/* Start Otp */
.otp_box {
    margin: 0 auto;
    width: 54%;
}
.enterOtp {
    width: 55px !important;
    height: 55px;
    margin-right: 10px;
    border: 1px solid #ffbed9;
    border-radius: 15px;
    background: #FFFBFD;
    font-size: 15px;
    color: rgba(98, 112, 138, 1);
    line-height: 23px;
}
.enterOtp:focus-visible{
    box-shadow: none !important;
    outline: none !important;
}
.resend_otp{
    font-size: 14px;
    line-height: 21px;
    color: rgba(144, 141, 161, 1);
}
.resend{
    color: rgb(173 53 179);
    cursor: pointer;
}
.resend:hover{
    color: rgba(49, 64, 92, 1);

}
.submit_text{
    color: #FFFFFF;
}
.submit_text:hover{
    color: #FFFFFF;
}
/* Start Recover Password */
.correct_password{
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #20A140;
}
.correctIcon{
    margin-right: 10px;
}
.incorrect_password {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A29FB4;
}
.incorrectIcon{
    margin-right: 10px;
}

/*Start Input field after fill background color css */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #FFFBFD inset !important;
    color: rgba(162, 159, 180, 1) !important;
}
/*End Input field after fill background color css */

